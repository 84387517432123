// index
.card {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);

  &:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  }
}

.card-img-top {
  height: 250px;
  object-fit: cover;
}

.list-group-flush .list-group-item .card-title:hover {
  color: #30afb8;
}

.accordion .accordion-button:hover {
  background-color: #f8f9fa;
}

.accordion .accordion-button:not(.collapsed) {
  background-color: white;
  border-top: 3px #2b9ea6 solid;
  box-shadow: none;
}

.text-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}
.text-indent {
  text-indent: 1rem;
}
.text-indent-2 {
  text-indent: 2rem;
}

.fs-md-4 {
  @include media-breakpoint-up(md) {
    font-size: 1.5rem !important;
  }
}

.circle {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-caption {
  z-index: 1;
}

.carousel-bg {
  width: 100%;
  height: 150px;
  background: linear-gradient(transparent, rgba(0, 0, 0, .55));
}

.map{
  height:350px;
  @include media-breakpoint-up(md) {
    height:550px;
  }
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover > .dropdown-menu {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

@media all and (min-width: 992px) {
	.dropdown-menu li{ position: relative; 	}
	.nav-item .submenu{ 
		display: none;
		position: absolute;
		left:100%; top:-7px;
	}
	.nav-item .submenu-left{ 
		right:100%; left:auto;
	}
	.dropdown-menu > li:hover{ background-color: #f1f1f1 }
	.dropdown-menu > li:hover > .submenu{ display: block; }
}	
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu{
      margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
  }
}	

.dropdown-toggle::after {
  display:none;
}